<template>
    <div class="container">
        <div class="row" style="height: 100px;"></div>
        <div class="row">
            <div class="col" style="text-align: left;">
                <h4>Chinook Art Gallery Auctioneer is a platform that wants everyone to have access to fine works of art. We are customer-oriented and have a team of experts who have accumulated extensive knowledge in the fields of Asian classical furniture, painting and calligraphy, ceramics, coins, ancient jade, study room, collision and other fields to market your fine antiques. It is our common purpose to spread the extensive and profound Chinese culture, so that more people can enjoy the spirit while appreciating the antiques, so that they continue to preserve and increase their value.</h4>
            </div>
        </div>
        <div class="row" style="height: 50px;"></div>
        <div class="row">
            <div class="col" style="text-align: left;">
                <h4>Chinook Art Gallery Auctioneer 是一个希望每个人都能接触到精美艺术品的平台，我们以客户为导向，有一个专家团队，在亚洲古典家具，书画，陶瓷，钱币，古玉，文房，景泰蓝等领域积累了广泛的知识，营销你的精美古董，传播博大精深的华夏文化，让更多的人在鉴赏古玩中得到精神享受的同时，使它们不断保值增值是我们的共同目的。</h4>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeTempBody'
}
</script>